import { getConfig } from '../config';

const accesstoken = localStorage.getItem("accesstoken");

export const api_config = async () => {
  try {
    console.log(process.env.NODE_ENV)
    // let endpoint = process.env.NODE_ENV === "development" ? "https://festaria-mypage.dev.upbond.io" : process.env.REACT_APP_API_ENDPOINT;
    let endpoint = process.env.REACT_APP_API_ENDPOINT;
    const auth_data = await getConfig();
    const api_auth = {
      mypage_host: endpoint,
      wallet_host: "https://nft-wallet.dev.upbond.io",
      config: {
        headers: {
          Authorization: "Bearer " + accesstoken,
          "X-origin": `${auth_data.configJson.domain}/`
        }
      },
      account: `${auth_data.configJson.account}`,
      aud: `${auth_data.configJson.client_id}`,
      iss: `${auth_data.configJson.domain}`,
    }

    if (accesstoken) {
      api_auth.config.headers = {
        Authorization: "Bearer " + accesstoken,
        "X-origin": `${auth_data.configJson.domain}/`,
    };

}
    return api_auth
  } catch(err) {
    console.log(err);
  }
}

export const reservation_config = {
  // TODO: Temp code for reservation feature
  api: 'https://api.dev.upbond.io',
  config: {
    headers: {
      account: 'e33381a0',
    }
  }
}

// export coonst warranty_config = async () =>{
//   const config = await getConfig();
//   const warrantyService = config.configJson.services.filter(x => x.name === "Warranty")?.[0];
// }

export const points_config = async () => {
  const config = await getConfig();
  const pointService = config.configJson.services.filter(x => x.name === "Points")?.[0];
  return {
    config: {
      endpoints: {
        add_point_url: pointService.add_point_url,
        sub_point_url: pointService.sub_point_url,
        balance_point_url: pointService.balance_point_url,
        history_point_url: pointService.history_point_url,
        warranty_url: pointService.warranty_url
      },
      headers: {
        // 'bote-api-key': 'abc' ←currently not used?
      }
    }
  }
}

export const orders_config = async () => {
  const config = await getConfig();
  const orderService = config.configJson.services.filter(x => x.name === "Order")?.[0];
  return {
    config: {
      endpoints: {
        order_url: orderService.order_url,
      },
      headers: {
        // 'bote-api-key': 'abc' ←currently not used?
      }
    }
  }
}


export const queryParam = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};