import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { motion, useCycle } from "framer-motion";
import Swal from "sweetalert2";
import "./style.scss";
import { AuthConfigContext } from "context/AuthConfigProvider";
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";
import useYappliScript from "utils/yappli";

const Sidebar = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [mobileMenu, setMobileMenu] = useCycle(false, true);
  const [toggleClass, setToggleClass] = useState("");
  const location = useLocation();
  const { logout } = useAuth0();
  const [servicesMenu, setServicesMenu] = useState([]);
  const authConf = useContext(AuthConfigContext);
  const [profileDetails] = useContext(ProfileDetailsContext);

  const sidemotion = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 32px 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
      },
    }),
    closed: {
      clipPath: "circle(0px at 40px 40px)",
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 18
      },
    },
  };

  useEffect(() => {
    const arrangeMenu = () => {
      if (
        authConf
        && authConf.configJson
        && authConf.configJson && authConf.configJson.services
      )
        {
          setServicesMenu(authConf.configJson && authConf.configJson.services);
        }
    };
    arrangeMenu();
  }, [authConf, history]);

  const LogoutConfirm = () => {
    Swal.fire({
      title: t("logout.title"),
      text: t("logout.subtitle"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: t("logout.btn_logout"),
      cancelButtonText: t("logout.btn_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        })
      }
    });
  };

  const checkPath = (link) => {
    const path = location.pathname.split("/");
    let real = `/${path[1]}`;
    // if (path[2]) {
    //   real = `/${path[1]}/${path[2]}`;
    // }

    if (real === link) {
      return "active";
    } else {
      return "";
    }
  };

  const setAction = () => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 300);
    } else {
      setOpen(true);
    }

    if (toggleClass === "") {
      setToggleClass("on");
    } else {
      setTimeout(() => {
        setToggleClass("");
      }, 600);
    }
  };

  const setActionMobile = () => {
    if (mobileMenu) {
      setMobileMenu(false);
    } else {
      setMobileMenu(true);
    }
  };

  const handleClick = (service, isMobile) => {
    if(service.name === "Ecommerce") {
      window.location.href = service.link
      history.push(service.link)
    } else if(isMobile) {
      setActionMobile();
      history.push(service.link)
    } else {
      history.push(service.link)
     }
  }

  return (
    <>
      { useYappliScript(profileDetails?.festaria_id) }
      <div className={`sidebar sidebar-desktop ${open ? "open" : ""}`} style={{ width: open ? "30%" : "0" }}>
        <div
          className={`toggle-icon ${open ? "open" : "close"}`}
          onClick={() => setAction()}
          style={{ right: open ? "36px" : "-42px" }}
        >
          {open ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
        </div>

        <div className={"side-content " + toggleClass}>
          <div className="profile">
            <div className="profile-image">
            {profileDetails && profileDetails.picture ? (
              <img src={profileDetails.picture} alt="user-pic" />
            ) : (
              <i className="fas fa-user"></i>
            )}
            </div>
            <p className="username">
              {
                profileDetails.identities && !profileDetails.identities.filter((i) => i.provider === "festaria").length ? "" :
                profileDetails && profileDetails.family_name && profileDetails.name ? profileDetails.family_name + ' ' + profileDetails.name :
                profileDetails && profileDetails.name ? profileDetails.name :
                "仮登録会員"
              }{" "}{
                profileDetails.identities && !profileDetails.identities.filter((i) => i.provider === "festaria").length ? t("homepage.temporary_member") : 
                t("homepage.honorary")
              }
            </p>
          </div>

          <ul className="menu-list">
            <li className={checkPath('/home')} onClick={() => history.push("/home")}>
              <i className="fas fa-home"></i>
              <span>{t("sidebar.mypage")}</span>
            </li>
            {servicesMenu && servicesMenu.length > 0 && (
              servicesMenu.map((service) => (
                <li key={service.key_path} className={checkPath(service.link)} onClick={() => handleClick(service, false)}>
                  <i className={service.icon}></i>
                  <span>{t(`${service.key_path}`)}</span>
                </li>
              ))
            )}

            <div className="alt-menu">
              {/* <li className="help">
                <i className="fas fa-question-circle"></i>
                <p>{t("sidebar.help")}</p>
              </li> */}
              <li className="logout" onClick={() => LogoutConfirm()}>
                <i className="fas fa-sign-out-alt"></i>
                <p>{t("sidebar.logout")}</p>
              </li>
            </div>
          </ul>
        </div>
      </div>

      <div className="sidebar sidebar-mobile">
        <div className="toggle-mobile">
          <i className="fas fa-bars" onClick={() => setMobileMenu(!mobileMenu)}></i>
        </div>
        <motion.div
          className={`side-content-mobile`}
          initial={false}
          animate={mobileMenu ? "open" : "closed"}
          variants={sidemotion}
        >
          <div className="close" onClick={() => setMobileMenu(!mobileMenu)}>
            <i className="fas fa-times"></i>
          </div>

          <div className="profile">
            <div className="profile-image">
              {profileDetails && profileDetails.picture ? (
                <img src={profileDetails.picture} alt="user-pic" />
              ) : (
                <i className="fas fa-user"></i>
              )}
            </div>
              {
                profileDetails.identities && !profileDetails.identities.filter((i) => i.provider === "festaria").length ? "" :
                profileDetails && profileDetails.family_name && profileDetails.name ? profileDetails.family_name + ' ' + profileDetails.name :
                profileDetails && profileDetails.name ? profileDetails.name :
                "仮登録会員"
              }{" "}{
                profileDetails.identities && !profileDetails.identities.filter((i) => i.provider === "festaria").length ? t("homepage.temporary_member") : 
                t("homepage.honorary")
              }
          </div>

          <ul className="menu-list">
            <li
              onClick={() => {
                setActionMobile();
                history.push("/home");
              }}
            >
              <i className="fas fa-home"></i>
              <span>{t("sidebar.mypage")}</span>
            </li>
            {servicesMenu && servicesMenu.length > 0 && (
              servicesMenu.map((service) => (
                <li
                  key={service.key_path}
                  className={checkPath(service.link)}
                  onClick={() => handleClick(service, true)}
                >
                  <i className={service.icon}></i>
                  <span>{t(`${service.key_path}`)}</span>
                </li>
              )
            ))}

            <div className="alt-menu">
              {/* <div className="help">
                <i className="fas fa-question-circle"></i>
                <span>{t("sidebar.help")}</span>
              </div> */}
              <div className="logout" onClick={() => LogoutConfirm()}>
                <i className="fas fa-sign-out-alt"></i>
                <span>{t("sidebar.logout")}</span>
              </div>
            </div>
          </ul>
        </motion.div>
      </div>
    </>
  );
};

export default Sidebar;
