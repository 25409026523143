import { useAuth0 } from "@auth0/auth0-react";
import React,{ useState, useContext } from "react";
import "./style.scss";

const Email = () => {
  const { loginWithRedirect } = useAuth0();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setsubmitError] = useState();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/home",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  const verifyEmail = async () => {
        console.log('verifyEmail')
        setSubmitLoading(true)
        setTimeout(function(){
            setSubmitLoading(false)
            setsubmitError("メールボックスの検証に失敗しました。lineでログインしてください")
        },3000)
  };
  
  return (
        <div className="emailPage">
            <div className="otp switch-content-flex height-99vh is-active">
                <div className="container">
                    <div className="container__frame">
                    <div className="email-card">
                        
                        <p className="msg-input-otp">メールボックスの検証</p>
                        <div className="subtitle">
                        毎回のログインでワンタイムパスコードを入力する必要があります。
                        <span className="line-breaker"><br /></span>
                        ご了承ください。
                        </div>
                        <p className="input-email-title">メールアドレス</p>
                        <form autocomplete="off" action="/interaction/gIC8wqz3BdMVp_9ZMqR4R/otp" method="post">
                        <div className="email-container">
                            <input name="email" className="email-input" type="text" val="email" />
                        </div>
                        {submitError && 
                            <div className="otp-form-message">{submitError}</div>
                        }
                        {submitLoading && 
                            <div className="otp-form-please-wait" >しばらくお待ちください。...</div>
                        }
                        <div className="btn-wrapper">
                            <button type="button" className="btn margin-0" disabled={submitLoading} onClick={() => verifyEmail()}>完了</button>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
        </div>
  )
    
};

export default Email;

