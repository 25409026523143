import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Animate from "components/animate/animate";
import { Profile_Update, AutoComplete_ZipCode } from "services/Profile";
import { useHistory } from "react-router-dom";
import { AuthConfigContext } from "context/AuthConfigProvider";
import Form from "@rjsf/core"
import { isFestariaAcct } from "utils/Ecommerce"
import { Update_Ecommerce_Profile } from "services/Ecommerce";
import ErrorTop from "components/error/error-top";
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";
import "./style.scss";

const ProfileEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const authConf = useContext(AuthConfigContext);
  const [schema, setSchema] = useState({});
  const [profileDetails] = useContext(ProfileDetailsContext);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uiSchema, setUiSchema] = useState({
    "ui:order": [
      "phone",
      "gender",
      "birthdate",
      "email",
      "*"
    ],
    "email": {
      "ui:placeholder": "example@gmail.com"
    },
    "gender": {
      "ui:widget": "radio"
    }
  });

  const resetAnniversaryValue = () => { 
    if(document.getElementById('root_custom_data_anniversary')){
        document.querySelector('#root_custom_data_anniversary').value = ""
    }
  };

  useEffect(() => {
    const initializeSchema = async () => {
      if (authConf && authConf.configJson && authConf.configJson.form_schema) {
        const formSchema = authConf.configJson.form_schema;
        setSchema(formSchema);
        const authUiSchema = authConf.configJson.ui_schema;
        // check if agreement is in form_schema's custom_data & configure ui:help feature
        if (formSchema.properties.custom_data && formSchema.properties.custom_data.properties.agreement) {
          if (authUiSchema) {
            const agreementUi = {
              "ui:help": (
                <div>
                  {authConf && authConf.configJson && authConf.configJson.pdf_agreement ?
                    (
                      <>
                        <a target="_blank" rel="noopener noreferrer" href={authConf.configJson.pdf_agreement}>
                          {t("profile_page.edit_page.lbl_terms")}
                        </a>
                      </>
                    ) :
                    (
                      <></>
                    )
                  }
                  {authConf || authConf.configJson && !authConf.configJson.pdf_agreement ?
                    (
                      <>
                        <a target="_blank" rel="noopener noreferrer" href="/agreement">
                          利用規約はこちら
                        </a>
                      </>
                    ) :
                    (
                      <></>
                    )
                  }
                </div>
              )
            };
            const anniversaryUi = {
              "ui:help": (
                <div>
                    <div>
                      <>
                      <button class="form-control" type="button" style={{ width:'180px',marginTop:'10px' }} onClick={() => resetAnniversaryValue()}>結婚記念日リセット</button>
                      </>
                    </div>
                </div>
              )
            };
            let combinedUiSchema = {
              ...authUiSchema
            };
            combinedUiSchema.custom_data = {
              ...authUiSchema.custom_data,
              agreement: agreementUi,
              anniversary: anniversaryUi
            };
            setUiSchema(prevState => ({ ...prevState, ...combinedUiSchema }));
          }
          else if (!authUiSchema) {
            const agreementUi = {
              "custom_data": {
                "agreement": {
                  "ui:help": (
                    <div>
                      {authConf && authConf.configJson && authConf.configJson.pdf_agreement ?
                        (
                          <>
                            <a target="_blank" rel="noopener noreferrer" href={authConf.configJson.pdf_agreement}>
                              {t("profile_page.edit_page.lbl_terms")}
                            </a>
                          </>
                        ) :
                        (
                          <></>
                        )
                      }
                    </div>
                  )
                }
              }
            };
            const anniversaryUi = {
              "custom_data": {
                "anniversary": {
                  "ui:help": (
                    <div>
                        <div>
                          <>
                          <button class="form-control" type="button" style={{ width:'180px',marginTop:'10px' }} onClick={() => resetAnniversaryValue()}>結婚記念日リセット</button>
                          </>
                        </div>
                    </div>
                  )
                }
              }
            };
            setUiSchema(prevState => ({ ...prevState, ...agreementUi, ...anniversaryUi }));
          }
        } 
        else if (authUiSchema) {
          setUiSchema(prevState => ({ ...prevState, ...authUiSchema }));
        }
      }
    };
    initializeSchema();
  }, [authConf, t]);

  useEffect(() => {
    const populateFormData = () => {
      const profileIsEmpty = Object.keys(profileDetails).length === 0;
      if (!profileIsEmpty) {
        for (let item in profileDetails) {
          if (profileDetails && profileDetails.custom_data && profileDetails.hasOwnProperty(item) && profileDetails.custom_data.hasOwnProperty(item)) {
            delete profileDetails[item]
          }
          if (profileDetails[item] === null) {
            profileDetails[item] = ""
          }
          // empty name if login with social
          if (item === "name" && profileDetails.identities && !profileDetails.identities.filter((i) => i.provider === "festaria").length) {
            profileDetails[item] = ""
          }
          if (item === "custom_data") {
            for (let custItem in profileDetails.custom_data) {
              // avoid date format errors with "anniversary" option (RRR client) -> set anniversary to undefined rather than ""
              if (custItem === "anniversary" && (profileDetails.custom_data[custItem] === "" || profileDetails.custom_data[custItem] === null)) {
                profileDetails.custom_data[custItem] = undefined;
              }
              if (profileDetails.custom_data[custItem] === null) {
                // for agreement boolean
                if (custItem === "agreement") {
                  profileDetails.custom_data[custItem] = false
                }
                if (custItem === "karen_flower") {
                  profileDetails.custom_data[custItem] = []
                }
                else {
                  profileDetails.custom_data[custItem] = ""
                }
              }
            }
          }
        }
        
        if (profileDetails.custom_data && profileDetails.custom_data.marital_status == null ) {
          profileDetails.custom_data.marital_status = '選択なし';
        }
        if (profileDetails.birthdate) {
          const convertedBirthDay = createBirthDateString(profileDetails.birthdate);
          setFormData({ ...profileDetails, birthdate: convertedBirthDay })
        } else {
          profileDetails.birthdate = null;
          setFormData(profileDetails)
        }
      }
    };
    populateFormData();
  }, [schema, profileDetails])


  const createBirthDateString = (day) => {
    let bdate = new Date(day)
    const offset = bdate.getTimezoneOffset();
    bdate = new Date(bdate.getTime() - (offset * 60 * 1000));
    return bdate.toISOString().split('T')[0];
  }

  const submit = async (values) => {
    let dataVals = values.formData;
    let formatProfileData = {};

    if (dataVals.birthdate) {
      formatProfileData = {
        ...dataVals,
        birthdate: new Date(dataVals.birthdate).toISOString(),
        birth_day: dataVals.birthdate.slice(8, 10),
        birth_month: dataVals.birthdate.slice(5, 7),
        birth_year: dataVals.birthdate.slice(0, 4),
      };
    } else {
      formatProfileData = {
        ...dataVals
      }
    }
    // if a custom_data item is undefined, give it an empty string. This prevents duplicate data in profile info from showing in the absence of a custom_data value
    if (formatProfileData && formatProfileData.custom_data) {
      for (let item in formatProfileData.custom_data) {
        if (formatProfileData.custom_data[item] === undefined) {
          formatProfileData.custom_data[item] = ""
        }
      }
    }


    if(document.getElementById('root_custom_data_anniversary')){
        if(document.querySelector('#root_custom_data_anniversary').value == ""){
          formatProfileData.custom_data['anniversary'] = ""
        }
    }
    // let name = "";
    // if(formatProfileData.first_name && formatProfileData.last_name) {
    //   name = `${formatProfileData.first_name} ${formatProfileData.last_name}`;
    //   formatProfileData.name = name;
    // } else if(formatProfileData.first_name && !formatProfileData.last_name) {
    //   name = formatProfileData.first_name;
    //   formatProfileData.name = name;
    // } else {
    //   name = formatProfileData.name;
    //   formatProfileData.name = name;
    // }

    // console.log('formatProfileData: ', formatProfileData);
    // return
    const token = localStorage.getItem("accesstoken");
    const submit = await Profile_Update(formatProfileData, token);
    if (submit) {
      console.log(submit);
      localStorage.setItem("userinfo", JSON.stringify(submit.data))
      Swal.fire({
        icon: "success",
        title: t("profile_page.edit_page.update_success_title"),
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      setTimeout(() => {
        Swal.close();
        window.location.href = "/profile";
      }, 3000);
    }
  }

  const festariaSubmit = async (values) => {
    let formValues = values.formData;

    setFormData(formValues);
    if (formValues?.custom_data?.zipcode) {
      formValues.custom_data.zipcode = formValues.custom_data.zipcode.toString();
    }
    // if user didn't input an anniversary date, this changes anniversary from undefined to empty string
    if (formValues?.custom_data) {
      for (let item in formValues.custom_data) {
        if (formValues.custom_data[item] === undefined) {
          formValues.custom_data[item] = ""
        }
      }
    }

    if(document.getElementById('root_custom_data_anniversary')){
        if(document.querySelector('#root_custom_data_anniversary').value == ""){
          formValues.custom_data['anniversary'] = ""
        }
    }
    const token = localStorage.getItem("accesstoken") || "";
    const updateFestariaProfile = await Update_Ecommerce_Profile(formValues, token);

    if (updateFestariaProfile?.message === "Success") {
      // console.log('2. updateFestariaProfile success:', updateFestariaProfile);
      Swal.fire({
        icon: "success",
        title: t("profile_page.create_page.edit_success"),
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      setTimeout(() => {
        Swal.close();
        window.location.href = "/home";
      }, 3000);
    } else {
      setError(true);
      let errorDescription = updateFestariaProfile?.response?.data?.errors?.error_description;
      if (errorDescription) {
        setErrorMessage(errorDescription)
      } else {
        setErrorMessage("会員情報を更新できませんでした");
      }
    }
  }

  useEffect(() => {
    // Reset error message after 7 seconds
    let festariaErrorDisplay = setTimeout(() => {
      setError(false)
      setErrorMessage('')
    }, 7000);

    return () => clearTimeout(festariaErrorDisplay)
  }, [error, errorMessage])

  // function validateEmails(formData, errors) {
  //   if ((formData.email !== formData.custom_data.emailConfirm) || !formData.custom_data.emailConfirm) {
  //     errors.custom_data.emailConfirm.addError("Emails don't match");
  //   }
  //   return errors;
  // }

  const customFormats = {
    'phone-jp': /^[0-9]*$/
  };

  function transformErrors(errors) {
    var errorMessage = [];
    var existErrors = [];
     errors.some(error => {
      // console.log(error)
      if(error.property === ".occupation" || error.property === ".anniversary" || error.property === ".building") {
        return
      }
      if(error.name === "enum") {
        if(error.property === ".agreement") {
          error.message= "利用規約の同意が必要になります"
          errorMessage.push(error)
        } else if (error.property === ".gender"){
          if(existErrors.includes(".gender")){
            return;
          }
          existErrors.push(error.property)
          error.message = "こちらは必須項目です"
          errorMessage.push(error)
        } else if (error.property === ".favorite_store"){
          if(existErrors.includes(".favorite_store")){
            return;
          }
          existErrors.push(error.property)
          error.message = "こちらは必須項目です"
          errorMessage.push(error)
        } else if (error.property === ".prefCode"){
          if(existErrors.includes(".prefCode")){
            return;
          }
          existErrors.push(error.property)
          error.message = "こちらは必須項目です"
          errorMessage.push(error)
        } else {
          error.message = "こちらは必須項目です";
        }
      }
      // if(error.property === ".family_name" || error.property === ".email") {
      //   error.message= "こちらは必須項目です"
      //   errorMessage.push(error)
      // }
      if(error.name === "required" && error.property != '.zipcode') {
        error.message = "こちらは必須項目です";
        errorMessage.push(error)
      }
      if(error.name === "type") {
        if(error.property == '.favorite_store' || error.property == '.prefCode' || error.property == '.gender'){
          return
        }
        if(error.params.type === "string" && !existErrors.includes(error.property)) {
          error.message = "こちらは必須項目です";
          errorMessage.push(error)
        }
      }
      if(error.name === "maxLength") {
        if(error.property === ".password") {
          // error.message = `8文字以上${error.params.limit || 16}文字以下を入力してください`
          // error.message = '半角英数字記号の8～16文字で入力してください。'
          error.message = '半角英数字記号の8～16文字で入力してください。「”」「,」「:」「<」「>」「[」「]」は利用できません。'
          errorMessage.push(error)
        } else {
          error.message = `${error.params.limit}文字以上ではありません`
          errorMessage.push(error)
        }
      }
      if(error.name === "minLength" || error.name === "pattern") {
        if (error.property === ".password") {
          // error.message = `半角英数字${error.params.limit || 8}文字以上で入力してください`
          error.message = '半角英数字記号の8～16文字で入力してください。'
          errorMessage.push(error)
        } else {
          error.message = `${error.params.limit}文字以下ではありません`
          errorMessage.push(error)
        }
      }
      if(error.name === "format") {
        if(error.params.format === "phone-jp") {
          error.message = "数字だけでお願いします"
          errorMessage.push(error)
        } else if(error.params.format === "email") {
          error.message = "有効なEメールを使用してください"
          errorMessage.push(error)
        } 
      }
      if(error.property === ".zipcode") {
        if(existErrors.includes(".zipcode")){
          return;
        }
        existErrors.push(error.property)
        error.message = `こちらは必須項目です。7桁の郵便番号（ハイフンなし）を入力してください。`
        errorMessage.push(error)
      }
      // if(error.message === "should match some schema in anyOf") {
      //   if(error.property === ".gender") {
      //     error.message = "こちらは必須項目です"
      //     errorMessage.push(error)
      //   } else {
      //     error.message = "";
      //   }
      // } 
      if(error.message === "should be boolean") {
        error.message = "";
      }      // return error;
    });

    return errorMessage;
    // return errors.map(error => {
    //   if (error.name === "enum") {
    //     if (error.property === ".custom_data.agreement") {
    //       error.message = "利用規約の同意が必要になります"
    //     } else if (error.property === ".gender") {
    //       error.message = ""
    //     } else {
    //       error.message = "こちらは必須項目です";
    //     }
    //   }
    //   if (error.name === "required") {
    //     error.message = "こちらは必須項目です";
    //   }
    //   if (error.name === "maxLength") {
    //     if(error.property === ".password") {
    //       error.message = `8文字以上${error.params.limit}文字以下を入力してください`
    //     } else {
    //       error.message = `${error.params.limit}文字以上ではありません`
    //     }
    //   }
    //   if (error.name === "minLength") {
    //     if (error.property === ".password") {
    //       error.message = `${error.params.limit}文字以上16文字以下を入力してください`
    //     } else {
    //       error.message = `${error.params.limit}文字以下ではありません`
    //     }
    //   }
    //   if (error.name === "format") {
    //     if (error.params.format === "phone-jp") {
    //       error.message = "数字だけでお願いします"
    //     } else if (error.params.format === "email") {
    //       error.message = "有効なEメールを使用してください"
    //     } else {
    //       error.message = `フォーマットは${error.params.format}と一致する必要があります`
    //     }
    //   }
    //   if (error.message === "should match some schema in anyOf") {
    //     if (error.property === ".gender") {
    //       error.message = "こちらは必須項目です"
    //     } else {
    //       error.message = "";
    //     }
    //   }
    //   if (error.message === "should be boolean") {
    //     error.message = "";
    //   }
    //   return error;
    // });
  };
  
  function onError (errors) {
    const errorField = document.querySelector('.field-error');
    if (errorField) errorField.scrollIntoView();
  }

  return (
    <Animate>
      <div className="profile-edit">
        <div className="card">
          {error && <ErrorTop message={errorMessage} />}
          <div className="card-title">
            <h1>{t("profile_page.edit_page.title")}</h1>
            <div className="title-bar"></div>
          </div>
          <div className="card-body">
            {schema && Object.keys(schema).length > 0 ? (
              <Form
                noHtml5Validate
                // validate={}
                transformErrors={transformErrors}
                onError={onError}
                customFormats={customFormats}
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                onSubmit={values => {
                  isFestariaAcct(authConf?.configJson?.account)
                    ? festariaSubmit(values)
                    : submit(values)
                }}
                onChange={AutoComplete_ZipCode}
              >
                <div className="action-area">
                  <div className="back">
                    <button type="button" className="btn btn-back" onClick={() => history.goBack()}>
                      <i className="fas fa-chevron-left"></i>
                    </button>
                  </div>
                  <div className="submission">
                    <button
                      type="submit"
                      className="btn btn-save"
                    >
                      {t("profile_page.edit_page.button_submit")}
                    </button>
                  </div>
                </div>
              </Form>
            ) : (
              <>
                <div className="no-schema">
                  <span>{t("common.no_info")}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default ProfileEdit;
