import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { getConfig } from "./config";
import history from "./utils/history";
import { Auth0Provider } from "@auth0/auth0-react";
import * as serviceWorker from "./serviceWorker";
import "i18n";
import Error from "pages/error/general";
import AuthConfigProvider from "context/AuthConfigProvider";
import { BrowserRouter } from "react-router-dom";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const runAfterGetConfig = async () => {
  try {
    const config = await getConfig();

    const providerConfig = {
      domain: config.configJson.domain,
      clientId: config.configJson.client_id,
      ...(config.configJson.audience ? { audiensce: config.configJson.audience } : null),
      redirectUri: config.configJson.fullRedirectUri || window.location.origin + (config.configJson.redirectUri || ''),
      onRedirectCallback,
    };

    // const domain = process.env.REACT_APP_LOGIN3_DOMAIN;
    // const clientId = process.env.REACT_APP_LOGIN3_CLIENT_ID;
    // const redirectUri = process.env.REACT_APP_LOGIN3_CALLBACK_URL;
  
  
    // if (!(domain && clientId && redirectUri)) {
    //   return null;
    // }

    // ReactDOM.render(
    //   <Auth0Provider
    //     domain={domain}
    //     clientId={clientId}
    //     authorizationParams={{
    //       redirect_uri: redirectUri, 
    //       scope: ''
    //     }}
    //     onRedirectCallback={onRedirectCallback}
    //   >
    //      <AuthConfigProvider authConfig={config}>
    //     <App />
    //     </AuthConfigProvider>
    //   </Auth0Provider>,
    //   document.getElementById("root")
    // );
  
    ReactDOM.render(
      <Auth0Provider
        domain={providerConfig.domain}
        clientId={providerConfig.clientId}
        authorizationParams={{
          redirect_uri: providerConfig.redirectUri, 
          scope: ''
        }}
        onRedirectCallback={onRedirectCallback}
      >
         <AuthConfigProvider authConfig={config}>
        <App />
        </AuthConfigProvider>
      </Auth0Provider>,
      document.getElementById("root")
    );


  
    // console.log(domain)
  
    // if (!(domain && clientId && redirectUri)) {
    //   return null;
    // }

    // const container = document.getElementById("root");
    // const root = createRoot(container);
    // console.log(onRedirectCallback)
    // ReactDOM.render(
    //     <Auth0Provider
    //     {...providerConfig}
    //     >
    //         <React.StrictMode>
    //           <Suspense fallback={<div>Loading...</div>}>
    //       <App />
    //       </Suspense>
    //         </React.StrictMode>
    //     </Auth0Provider>,
    //   document.getElementById("root")
    // );
    

    // ReactDOM.render(
    //   <Auth0Provider {...providerConfig}>
    //     <AuthConfigProvider authConfig={config}>
    //       <React.StrictMode>
    //         <Suspense fallback={<div>Loading...</div>}>
    //           <App />
    //         </Suspense>
    //       </React.StrictMode>
    //     </AuthConfigProvider>
    //   </Auth0Provider>,
    //   document.getElementById("root")
    // );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  } catch (error) {
    console.error(error);
    ReactDOM.render(
      <Error error={error.message}/>,
      document.getElementById("root")
    )
  }
};

runAfterGetConfig();
