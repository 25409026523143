import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthConfigContext } from "context/AuthConfigProvider";
import "./style.scss";
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";

const HeaderMobile = () => {
  const authConf = useContext(AuthConfigContext);
  const { t } = useTranslation();
  const [profileDetails] = useContext(ProfileDetailsContext);

  return (
    <div className="header-mobile">
      <div className="logo-zone">
        {authConf.configJson && authConf.configJson.brand_ui && authConf.configJson.brand_ui.logo ? (
          <img src={authConf.configJson.brand_ui.logo} alt="Brand logo"/>
        ) :
          <></>
        }
      </div>
      <div className="personal-info-zone">
        <div className="profile-zone">
          {profileDetails && profileDetails.picture ? (
            <img src={profileDetails.picture} alt="user-pic" />
          ) : (
            <i className="fas fa-user"></i>
          )}
        </div>
        <div className="biodata">
          <ul>
            <li className="name">
              {
                profileDetails.identities && !profileDetails.identities.filter((i) => i.provider === "festaria").length ? "" :
                profileDetails && profileDetails.family_name && profileDetails.name ? profileDetails.family_name + ' ' + profileDetails.name :
                profileDetails && profileDetails.name ? profileDetails.name :
                "仮登録会員"
              }{" "}{
                profileDetails.identities && !profileDetails.identities.filter((i) => i.provider === "festaria").length ? t("homepage.temporary_member") : 
                t("homepage.honorary")
              }
            </li>
            {/* <li className="email">{profileDetails ? profileDetails.email : "-"}</li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderMobile;
