import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from "react-router-dom"
import Animate from "components/animate/animate";
import "./style.scss";
import { t } from 'i18next';
import GrayBar from "../../../components/points/gray-bar";
import CancelBtn from 'components/points/cancel-btn';
import { useHistory } from "react-router-dom";
import UsePointsBtn from "../../../components/points/use-points-btn";
import { Get_Balance } from 'services/Points';
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";

const PointsInput = () => {
  const history = useHistory();
  const [startingAmount, setStartingAmount] = useState(0);
  const [profileDetails] = useContext(ProfileDetailsContext);
  const [mounted, setMounted] = useState(true)


  const [inputVal, setInputVal] = useState("");
  const [calculatedAmount, setCalculatedAmount] = useState(0);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const getBalance = useCallback(async (details) => {
    console.log(details);
    let body = {}
    // body.userId = details?.identities?.find(x => { return x.provider === 'festaria'})?.user_id;
    body.userId = details.ec_id;
    if (!body.userId) {
      body.userId = details.festaria_id;
    }
    if (!body.userId) {
      return;
    }
    const balance = await Get_Balance(body);
    console.log('balance:', balance);
    setStartingAmount(balance.balancePoint);
  }, [])

  const getAmount = (e) => {
    let input = e.target.value;
    let calculation = startingAmount - input;
    if(e.target.value === "") {
      setInputVal(0)
    } else {
      setInputVal(parseInt(e.target.value));
    }
    setCalculatedAmount(calculation);
  }

  const sendInfo = (e) => {
    e.preventDefault();
    if(calculatedAmount >= 0 && (inputVal && inputVal !== "" && inputVal >= 0)) {
      history.push(
        "/point/confirm",
        {
          pointsTotal: startingAmount,
          pointsToUse: inputVal,
          pointsRemaining: calculatedAmount
        }
      )
    } else {
      setError(true);
      setErrorText("points_page.points_error");
    }
  }

  useEffect(() => {
    if (mounted) {
        getBalance(profileDetails)
          .catch(err => console.log('error in getBalance', err));
      }
    return () => setMounted(false);
  }, [getBalance, mounted, profileDetails])

  useEffect(() => {
    setCalculatedAmount(startingAmount)
  }, [startingAmount])

  return (
    <Animate>
      <div className="points-input">
        <GrayBar>
          <Link to="/point">
            <button type="button">
              <i className="fas fa-chevron-left"></i>
              {t("points_page.back_to_points_home")}
            </button>
          </Link>
        </GrayBar>
        {error && (
          <div className="error-message">
            {t(`${errorText}`)}
          </div>
        )}
        <div className="main-section">
          <div className="main-top">
            <p>{t("points_page.points_amount_text")}</p>
            <h1>
              <span className="top-amount">{startingAmount}</span> pt
            </h1>
          </div>
          <div className="main-mid">
            <p style={{ padding: '0% 8%'}}>{t("points_page.points_input")}</p>
            <form className="point-form">
              <div className="flex" style={{ padding: '0% 8%'}}>
                <input onChange={(e) => getAmount(e)} type="number" val={inputVal} />
                <label>pt</label>
              </div>
              <div className="points-page-caption">
            <p>ポイントは1,000 pt単位でご利用いただけます</p>
            </div>
              <hr />
              <div className="main-bottom">
                <div className="bottom-points">
                  <p>{t("points_page.points_after_use")}</p>
                  <h1>
                    <span className="top-amount">{calculatedAmount}</span> pt
                  </h1>
                </div>
                <div className="bottom-actions">
                  <UsePointsBtn text={t("points_page.use_points")} isLink={false} callback={sendInfo} />
                  <CancelBtn text={"points_page.cancel"} path={"/point"} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Animate>
  );
}

export default PointsInput;
