import React, { useState, useEffect, useContext, useCallback } from "react";
import Animate from "components/animate/animate";
import { useTranslation } from "react-i18next";
import "./style.scss";
import GrayBar from "../../../components/points/gray-bar";
import UsePointsBtn from "../../../components/points/use-points-btn";
import { Points_History, Get_Balance } from "services/Points";
import { ProfileDetailsContext } from "context/ProfileDetailsProvider";

const PointsHome = () => {
  const { t } = useTranslation();
  const [useTransHistory, setTransHistory] = useState([]);
  const [useShowMore, setShowMore] = useState(false);
  const [useBalance, setBalance] = useState(0);
  const [usePageInfo, setPageInfo] = useState({});
  const [usePager, setPager] = useState([]);
  const [profileDetails] = useContext(ProfileDetailsContext);
  const [mounted, setMounted] = useState(true)

  const getBalance = useCallback(async (details) => {
    console.log(details);
    let body = {}
    body.userId = details?.identities?.find(x => { return x.provider === 'festaria'})?.user_id;
    if (!body.userId) {
      body.userId = details.festaria_id;
    }
    if (!body.userId) {
      return;
    }
    const balance = await Get_Balance(body);
    console.log('balance:', balance);
    setBalance(balance.balancePoint);
  }, [])

  const getPointHistory = useCallback(async (page, details) => {
    let body = {
      page: page,
      size: 10
    }
    body.userId = details?.id;
    // body.userId = "6831";

    // if (!body.userId) {
    //   body.userId = details.festaria_id;
    // }
    if (!body.userId) {
      return;
    }
    let history = await Points_History(body);

    console.log('history:', history);
    const transHistory = history.data;
    if(transHistory.length > 0) {
      console.log('transHistory:', transHistory);
      setTransHistory(transHistory)
    }
    setPageInfo(history.pageInfo);
    const pager = [];

    // 全部で「…含めて7個の要素に収まる」
    // 全部の要素が7個以下 => 全部表示
    // …8個以上 => 下記ロジックに従う
    // 全部で19ページの場合(pageInfo.pages = 19)
    // 1~4 => 1,2,3,4,5 … 19
    // 5~15 => 1 … 4,5,6 … 19
    // 16 => 1 … 15,16,17,18,19

    // 仮に8個だった場合
    // 1~4 => 1,2,3,4,5 … 8
    // 5 => 1 … 4,5,6,7,8

    
    if (history.pageInfo.pages <= 7) {
      // 要素数が7個以下
      for(var i = 0; i < history.pageInfo.pages; i++){
        pager.push(i + 1);
      }
    } else {
      // 要素数が7個以上
      // pager.push(1);
    
      if (page <= 3) {
        for (var i = 1; i < 6; i++) {
          pager.push(i);
        }
        pager.push(0);
        pager.push(history.pageInfo.pages);
      } else if (page >= history.pageInfo.pages - 3) {
        pager.push(1);
        pager.push(0);
        for (var i = -4; i < 1; i++) {
          pager.push(history.pageInfo.pages + i);
        }
      } else {
        // 1 … x,y,z … 最終ページ
        pager.push(1);
        pager.push(0);
        for (var i = -1; i < 2; i++) {
          pager.push(page + i);
        }
        pager.push(0);
        pager.push(history.pageInfo.pages);
      }
    }
    setPager(pager);
  }, [])

const onPageBack = useCallback(async () =>{
  if(usePageInfo.page === 1){
    return;
  }
  getPointHistory(usePageInfo.page - 1,profileDetails)
}, [usePageInfo,profileDetails,getPointHistory])
const onPageNext = useCallback(async () =>{
  if(usePageInfo.page === usePageInfo.pages){
    return;
  }
  getPointHistory(usePageInfo.page + 1,profileDetails)
}, [usePageInfo,profileDetails,getPointHistory])
const onPageMove = useCallback(async (page) =>{
  if(usePageInfo.page === page ||
    page === 0){
    return;
  }
  getPointHistory(page,profileDetails)
}, [usePageInfo,profileDetails,getPointHistory])

  useEffect(() => {
    if(mounted) {
      getBalance(profileDetails)
        .catch(err => console.log('error in getBalance', err));
      getPointHistory(1, profileDetails)
        .catch(err => console.log('error in getPointHistory', err));
    }

    return () => setMounted(false);
  }, [profileDetails, getBalance, getPointHistory, mounted]);

  const formatDate = (d) => {
    let date = new Date(d)
    let month = function() {
      let m = date.getMonth();
      let str = m < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
      return str;
    }
    let day = () => {
      let d = date.getDate();
      let str = d < 10 ? `0${d}` : d;
      return str;
    }
    let string = `${date.getFullYear()}.${month()}.${day()}`;
    return string;
  }

  const showMoreItems = () => {
    setShowMore(!useShowMore);
  }

  const numberOfTransactions = useShowMore ? useTransHistory.length : 5;

  return (
    <Animate>
      <div className="points-home">
        <div className="top-info">
          <p>{t("points_page.points_amount_text")}</p>
          <h1>
            <span className="top-amount">{useBalance > 0 ? useBalance :0}</span> pt
          </h1>
          <UsePointsBtn text={"points_page.use_points"} path={"/point/input"} />
        </div>
        <div className="history-wrapper">
          <GrayBar>
            <p>{t("points_page.points_history")}</p>
          </GrayBar>
          <div className="history-list">
            <ul>
              {useTransHistory.length > 0 ? (
                useTransHistory && useTransHistory.slice(0, numberOfTransactions).map((item, idx) => (
                  <li key={idx}>
                    <div className="history-item">
                      <p>{formatDate(item.created_at)}</p>
                      <div
                        className="history-item-transaction"
                        // style={{"color": `${item.amount.charAt(0) === '-' ? "red" : ""}`}}
                      >

                        <p>
                          <span className="history-item-amount-prefix"
                          style={{"color": `${item.type === 'REDEEM' || item.type === 'EXPIRED' ? "red" : ""}`}}>{item.type === 'REDEEM' || item.type === 'EXPIRED' ? '-' : '+'}</span>
                          <span className="history-item-amount"
                        style={{"color": `${item.type === 'REDEEM' || item.type === 'EXPIRED' ? "red" : ""}`}}
                        >{item.amount}</span> <span style={{color: "black"}}>pt</span></p>
                      </div>
                    </div>
                    <hr />
                  </li>
                ))
              ) : (
                <div className="no-history">{t("points_page.no_transaction_history")}</div>
              )}
            </ul>
            {!useShowMore && (
              <div className="see-more">
                <button
                  onClick={() => showMoreItems()}
                >
                  {t("points_page.see_more")}
                </button>
              </div>
            )}
            {useShowMore && (
              
            <div className={useTransHistory.length === 0 ? 'hide' : 'pagenation'}>
            <div className="back">
                    <button type="button" className="btn btn-back" onClick={() => onPageBack()}>
                      <i className="fas fa-chevron-left"></i>
                    </button>
                  </div>
                  {usePager.map((pageNum, idx) => (
              <div key={`${idx}`} className="page-number">
                <button type="button" className={usePageInfo.page === pageNum?'btn active':'btn'} onClick={() => onPageMove(pageNum)}>
                  {pageNum === 0 ? '…': pageNum}
                </button>  
              </div>
            ))}
            <div className="next">
                    <button type="button" className="btn btn-next" onClick={() => onPageNext()}>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
            </div>
            )}
          </div>
        </div>
      </div>
    </Animate>
  )
}

export default PointsHome;