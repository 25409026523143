import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { AuthConfigContext } from 'context/AuthConfigProvider';
import { useTranslation } from 'react-i18next';
import { hasFestariaId, hasRegisterUser } from 'utils/Ecommerce';

const Actions = ({services}) => {
  const { t } = useTranslation();
  const items = services
    .filter(service => service.name !== "Profile")
    .map((item, idx) => (
      {
        name: t(`${item.key_path}`),
        link: item.link
      }
    ));
  return (
    <>
      {items.map((action, idx) => (
        <a href={action.link} className="action" key={`${action}-${idx}`}>{action.name}</a>
      ))}
    </>
  )
}

const HasEcommerce = () => {
  return (
    <>
      <div className="green-signup">
        <Link to="/profile/edit">
          <div className="link-inner">
            <button>登録情報変更</button>
          </div>
        </Link>
        <Link to="/profile/withdraw">
          <div className="link-inner">
            <button>退会</button>
          </div>
        </Link>
      </div>
    </>
  )
}

const NoEcommerce = () => {
  return (
    <>
    <div className="ecommerce-recomend-caption">
      <p>お客さまはfestaria Members Clubの仮登録まで完了しており、<br></br>店舗でポイントサービスをご利用いただけます<br></br>
メンバー登録が完了すると、オンラインショップでのお買物でもポイントの獲得・利用が可能になります。<br></br>さらに、メンバー登録が完了したお客様には500ptプレゼント！<br></br>ぜひ今すぐご登録ください。</p>
<p>フェスタリア公式オンラインショップのアカウントをお持ちの方はこちら</p>
      </div>
      <div className="green-signup">
        <a href="/ecommerce/login">
          <div className="link-inner">
            <button>メンバー登録(ポイント連携)</button>
          </div>
        </a>
        <div className="ecommerce-recomend-caption2">
          <p>新規にご登録の方はこちら</p>
        </div>
        <Link to="/profile/create">
          <div className="link-inner">
            <button>新規メンバー登録</button>
          </div>
        </Link>
      </div>
    </>
  )
}

const HasEcommerceNoRegister = () => {
  return (
    <>
    <div className="ecommerce-recomend-caption">
      {/* <p><br></br>お客様はすでにメンバー登録（ポイント連携）まで完了しております。<br></br>会員情報を以下までご登録ください。<br></br></p> */}
      </div>
      <div className="green-signup">
        <Link to="/profile/edit">
          <div className="link-inner">
            <button>登録情報変更</button>
          </div>
        </Link>
      </div>
    </>
  )
}

const ECommerceHomePage = ({profile}) => {
  const authConf = useContext(AuthConfigContext);
  return (
    <>
      <div className="actions">
        <Actions services={authConf?.configJson?.services} />
      </div>
      {hasFestariaId(profile?.identities) ? (hasRegisterUser(profile) ?  <HasEcommerce />  :  <HasEcommerceNoRegister />) : (
        <NoEcommerce />
      )}
    </>
  );
}

export { ECommerceHomePage };
