import React from "react";
import "./style.scss";
const ErrorTop = ({message}) => {
  const safeText = message.split('\n').map((line, index) => (
    <span key={index}>{line}<br /></span>
  ));
  return (
    <div className="error-top">
      {safeText}
    </div>
  );
}

export default ErrorTop;
