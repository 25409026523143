import React, { useContext, useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import Animate from "components/animate/animate";
import "./style.scss";
import comingsoon from "assets/misc/comingsoon.svg";
import { AuthConfigContext } from "context/AuthConfigProvider";

const Error = ({error}) => {
  const authConf = useContext(AuthConfigContext);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if(error.includes("configJson")){
      window.location.href = "https://www.upbond.io"
      return
    }
    setShowError(true);
  }, [])

  return showError &&
    <Animate>
      <div className="auth-error">
        <div className="card">
          <div className="card-body">
            <img src={comingsoon} alt="auth-error" />
            <h1>
              { error ? (
                <>
                  {error}
                </>
                ) : (
                  <>
                    エラーが発生しました。原因が不明な場合は、プロバイダにお問い合わせください。
                  </>
                )
              }
            </h1>
            {
              authConf && (
                <>
                  <p>予期しない原因により操作が中断されました。お手数ですが、再度、以下ボタンより操作をお願い致します。</p>
                  <button className="logout" onClick={() => {window.location = `${authConf.configJson.domain}/v2/logout`}}>
                    Relogin
                  </button>
                </>
              )
            }
            {/* <div className="back" onClick={() => history.goBack()}>
              back to previous page
            </div> */}
          </div>
        </div>
      </div>
    </Animate>
};

export default Error;
