import React, { useEffect, useContext } from "react";
import { Router, Route, Switch } from "react-router-dom";
import Sidebar from "components/sidebar";
import PageContent from "components/page-content/content";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "components/Loading";
import history from "./utils/history";
import Error from "./pages/error/general";
import VerifyEmail from "./pages/error/verify-email";
import { AuthConfigContext } from "context/AuthConfigProvider";
import ProfileDetailsProvider from "context/ProfileDetailsProvider";
import Login from "pages/login";
import Email from "pages/email";

// styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./global.scss";

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
    returnTo:"/",
    ...args,
  });
  return <Component />;
};

const MaintenanceComponent = () => {
  return (
    <div className="main-content">
      メンテナンス中のため、現在仮会員からのメンバー登録を一時的に停止しております。 
      お客さまにご不便、ご迷惑をお掛けいたしますことを深くお詫び申し上げます。
      オンラインショップでの購入のお客様はゲスト購入をご利用いただきますようお願いいたします。
      メンテナンス完了予定は6月25日09:00見込みです。
      恐れ入りますが、メンテナンス完了後にご利用いただきますようお願いいたします。
    </div>
  );
};

const App = () => {
  const { isLoading, error } = useAuth0();
  const authConfig = useContext(AuthConfigContext);
  const brandUi = authConfig.configJson.brand_ui;
  // useEffect(() => {
  //   var titleImg = document.querySelector("link[rel*='icon']");
  //   if (brandUi && brandUi.logo) {
  //     titleImg.href = brandUi.logo
  //   }
  // },[])

  useEffect(() => {
    const setBrandColors = () => {
      if (brandUi && brandUi.backgroundColor) {
        document.documentElement.style.setProperty(
          "--color-brand-bg",
          brandUi.backgroundColor
        );
      }
      if (brandUi && brandUi.primaryColor) {
        document.documentElement.style.setProperty(
          "--color-brand-primary",
          brandUi.primaryColor
        );
      }
    };
    setBrandColors();
  }, [brandUi, authConfig]);

  if (error) {
    console.log("error message:", error.message);
    if (
      error.message ===
        "User's email is not verified yet. Please check your email for verification email" ||
      error.message ===
        "User's email is not verified yet. Please check your email for verification email."
    ) {
      return <VerifyEmail error={error.message} />;
    } else if (
      error.message === "Invalid state" ||
      error.message === "grant request is invalid"
    ) {
      window.location.href =
        window.location.origin + (authConfig.configJson.redirectUri || "");
    } else {
      return <Error error={error.message} />;
    }
    // localStorage.setItem("error", error.message);
    // window.location.href = window.location.origin;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div className="outer-container">
        {authConfig?.configJson?.maintenance ? (
          <Switch>
            <Route
              exact
              path={`/profile/create`}
              component={MaintenanceComponent}
            />
            <Route
              exact
              path={`/profile/create/buy`}
              component={MaintenanceComponent}
            />
            <Route
              exact
              path={`/ecommerce/login`}
              component={MaintenanceComponent}
            />
             <Route
              exact
              path={`/`}
              component={Login}
            />
            <Route
              exact
              path={`/email`}
              component={Email}
            />
            <ProfileDetailsProvider>
              <Sidebar />
              <ProtectedRoute
                component={PageContent}
                returnTo={authConfig.configJson.redirectUri || "/"}
              />
            </ProfileDetailsProvider>
          </Switch>
        ) : (
          <Switch>
            <Route
              exact
              path={`/`}
              component={Login}
            />
             <Route
              exact
              path={`/email`}
              component={Email}
            />
          <ProfileDetailsProvider>
            <Sidebar />
            <ProtectedRoute
              component={PageContent}
              returnTo={authConfig.configJson.redirectUri || "/"}
            />
          </ProfileDetailsProvider>
          </Switch>
        )} 
      </div>
    </Router>
  );
};

export default App;
